<template>
<div>
  Unsere offenen Stellen sind unter anderem auf der Homepage des Erzbistums Bamberg unter folgendem Link veröffentlicht:
  <br>
  <br>
  <a href="https://personal.kirche-bamberg.de/offene-stellen/kirchenstiftungen" target="_blank">
    Stellenangebote
  </a>
</div>
</template>

<script>
export default {
  name: "Verein"
}
</script>

<style scoped>

</style>