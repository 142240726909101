<template>
  <div id="app">
    <ScrollTopArrow/>
    <div class="columns">
      <div class="column pb-0">
        <div id="header" class="columns is-variable">
          <div class="column is-one-quarter pb-0">
            <figure id="logo-image" class="is-5by4">
              <img src="./assets/images/st-elisabeth-logo_5by4.png" class="pt-3" alt="Logo">
            </figure>
          </div>
          <div class="column is-three-quarters-desktop pt-0 pr-0 pb-0">
            <div class="column pb-0 pl-6 is-hidden-mobile">
              <figure>
                <img class="banner" src="./assets/images/st-elisabeth-header-komplett_5by1.png" alt="Header">
              </figure>
            </div>
            <div class="column pt-0 pr-0">
              <TopMenu/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <div id="content-container" class="columns is-variable">
          <div id="side-menu" class="column is-one-quarter pl-0 mt-6">
            <SideMenu/>
          </div>
          <div id="content" class="mt-6 column is-half-desktop is-three-quarters-tablet">
            <div id="content-view" class="p-2">
              <transition name="fade" mode="out-in" @after-leave="afterLeave">
                <router-view></router-view>
              </transition>
            </div>
          </div>
          <div class="column is-one-quarter pr-0 pb-0 pt-0 is-hidden-touch">
            <figure class="image is-1by3">
              <img src="./assets/images/st-martin.jpg" alt="St.Martin">
            </figure>
          </div>
        </div>
      </div>
    </div>
    <div class="columns footer">
      <div class="column">
        <Footer/>
      </div>
    </div>
  </div>
</template>

<script>
import TopMenu from "@/components/TopMenu";
import SideMenu from "@/components/SideMenu";
import Footer from "@/views/Footer";
import ScrollTopArrow from "@/components/ScrollTopArrow";

export default {
  name: 'App',
  components: {
    ScrollTopArrow,
    Footer,
    SideMenu,
    TopMenu
  },
  methods: {
    afterLeave () {
      this.$root.$emit('triggerScroll')
    }
  }
}
</script>

<style>
.banner {
  position: relative;
  margin-bottom: -0.6rem;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.sticky {
  position: sticky !important;
  top: 10px;
}

#content {
  overflow: auto;
}

#logo-image {
  background: white;
}

@media only screen and (min-width: 600px) {
  #content {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .banner-right {
    margin-top: -2.25rem;
  }
  #logo-image {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

@media only screen and (max-width: 1050px) {
 body {
   background-image: url("assets/images/st-martin.jpg");
   background-repeat: no-repeat;
   background-attachment: fixed;
   background-size: cover;
   width: 100%;
   height: 100%;
 }
  #content-view {
    background: #ffffffde;
  }
}

@media only screen and (min-width: 1200px) {
  #logo-image {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  #content {
    height: 115rem;
  }
  #content-view {
    overflow-y: auto;
  }
  #header {
    margin-bottom: -2.5rem;
  }
}

.navbar-burger {
  margin-right: 1rem;
}
</style>
