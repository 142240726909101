import { render, staticRenderFns } from "./Kontakt.vue?vue&type=template&id=b10477fe&scoped=true"
import script from "./Kontakt.vue?vue&type=script&lang=js"
export * from "./Kontakt.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b10477fe",
  null
  
)

export default component.exports