<template>
  <a onclick="document.getElementById('start-item').scrollIntoView()" class="is-hidden-desktop" id="back2Top" title="Back to top">&#10148;</a>
</template>

<script>
export default {
  name: "ScrollTopArrow",
  methods: {
    hideOnScroll() {
      let arrow = document.getElementById('back2Top');
      let content = document.getElementById('content-view');
      let contentRect = content.getBoundingClientRect();

      if (contentRect.top <= document.documentElement.scrollTop) {
        arrow.style.display = 'unset';
      } else {
        arrow.style.display = 'none';
      }
    }
  },
  created() {
    const mq = window.matchMedia( "(max-width: 740px)" );

    if (mq.matches) {
      document.addEventListener('scroll', this.hideOnScroll);
    }
  },
  beforeDestroy() {
    document.removeEventListener('scroll', this.hideOnScroll);
  }
}
</script>

<style scoped>
#back2Top {
  display: none;
  overflow: hidden;
  z-index: 999;
  cursor: pointer;
  -moz-transform: rotate(270deg);
  -webkit-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
  position: fixed;
  bottom: 2rem;
  right: 1rem;
  color: #555;
  text-align: center;
  font-size: 30px;
  text-decoration: none;
}
#back2Top:hover {
  background-color: #DDF;
  color: #000;
}
</style>