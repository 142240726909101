<template>
  <nav class="navbar" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
      <a role="button" class="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbar">
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>

    <div id="navbar" class="navbar-menu">
      <div class="navbar-start">
        <a href="https://kindergarten-neunkirchen-am-brand.de" target="_blank" class="navbar-item ml-3 pl-5 pr-5">
          Kindergarten
        </a>
        <a href="https://kinderkrippe-neunkirchen.de/" target="_blank" class="navbar-item ml-3 pl-5 pr-5">
          Kinderkrippe
        </a>
      </div>
      <div class="navbar-item is-expanded ml-3 fill-item"/>
    </div>
  </nav>
</template>

<script>
export default {
  name: "TopMenu"
}
</script>

<style scoped>
</style>