<template>
  <footer>
    <div class="content has-text-centered has-text-link-light">
      Stand 13.06.2024
      <router-link
          :to="'/kontakt'"
          class="has-text-link-light ml-6" @click.native="scrollToTop">
        Kontakt
      </router-link>
      <router-link
          :to="'/impressum'"
          class="has-text-link-light ml-6" @click.native="scrollToTop">
        Impressum
      </router-link>
      <router-link
          :to="'/datenschutz'"
          class="has-text-link-light ml-6" @click.native="scrollToTop">
        Datenschutz
      </router-link>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  methods: {
    scrollToTop() {
      window.scrollTo(0,0);
    }
  }
}
</script>

<style scoped>
</style>