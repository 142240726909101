import { render, staticRenderFns } from "./ScrollTopArrow.vue?vue&type=template&id=64ae61c6&scoped=true"
import script from "./ScrollTopArrow.vue?vue&type=script&lang=js"
export * from "./ScrollTopArrow.vue?vue&type=script&lang=js"
import style0 from "./ScrollTopArrow.vue?vue&type=style&index=0&id=64ae61c6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64ae61c6",
  null
  
)

export default component.exports