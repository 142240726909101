export const scrollOptions = {
    container: "#content",
        duration: 1200,
        easing: "ease",
        offset: 0,
        cancelable: true,
        onStart: false,
        onDone: scrollBodyIfNeeded,
        onCancel: false,
        x: false,
        y: true
}

function scrollBodyIfNeeded(element) {
    setTimeout(function () {
        if (!isInViewPort(element)) {
            element.scrollIntoView({behavior: "smooth", block: "start"});
        }
    }, 100);
}
function isInViewPort(element) {
    let rect = element.getBoundingClientRect();
    let html = document.documentElement;
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (html.clientHeight) &&
        rect.right <= (html.clientWidth)
    );
}