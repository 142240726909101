<template>
  <div class="block">
    <h1>St. Elisabethenverein Neunkirchen am Brand</h1>
    <br>
    <h2>Verwaltung</h2>
    Frau Christine Kröhn und Frau Heike Gutzeit<br>
    Mühlwiesenweg 20<br>
    91077 Neunkirchen am Brand<br>
    <br>
    Telefon: 09134 / 2290020<br>
    info@elisabethenverein-nk.de<br>
    <br>

    <h2>1.Vorsitzender</h2>
    Herr Pfarrer Joachim Cibura: pfarrer.cibura@elisabethenverein-nk.de<br>
    <br>
    <h2>Trägervertretung der Kindertagesstätten:</h2>
    Frau Pia Mayer<br>
    <br>
    <h2>Vertreter Ökumenischer Familienstützpunkt:</h2>
    Frau Christine Sitter<br>
    Frau Simone Seubert<br>
    <br>
  </div>
</template>

<script>
export default {
  name: "Datenschutz"
}
</script>

<style scoped>

</style>