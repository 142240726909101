<template>
<div class="content">
  <p>
    Bitte öffnen Sie den folgenden Link um unsere
    <a href="/files/Beitrittserklaerung.pdf" target="_blank">Beitrittserklärung</a>
    einzusehen.<br>
    <br>

    Diese füllen Sie bitte aus und senden sie an <a href="mailto:info@st-elisabethenverein-nk.de">info@st-elisabethenverein-nk.de</a>
  </p>

  <p>
    *Anliegen / Anfragen an einzelne Vorstandsmitglieder können bei Bedarf über die Verwaltung an die entsprechenden Personen weitergeleitet werden.
  </p>
</div>
</template>

<script>
export default {
  name: "Verein"
}
</script>

<style scoped>

</style>