<template>
  <div class="block">
    Angaben gemäß § 5 TMG,
    St. Elisabethenverein Neunkirchen am Brand,
    vertreten durch den 1. Vorsitzenden:
    Herrn Pfarrer Joachim Cibura,
    Kirchplatz 4,
    91077 Neunkirchen am Brand
  </div>
</template>

<script>
export default {
  name: "Impressum"
}
</script>

<style scoped>

</style>