<template>
<div>
  <div class="content">
    <h1>Heilige Elisabeth</h1>
    <h2>Namens- und Gedenktag</h2>
    <p class="block">
      <strong>19. November</strong>, Landespatronin von Hessen und Thüringen, Heilige im Katholizismus und Protestantismus
    </p>
    <br>
    <h2>Vita</h2>
    <p>
      <strong>Geboren:</strong> 07. Juli 1207 in Ungarn, wo genau ist nicht bekannt, vermutlich Pressburg
    </p>
    <p>
      <strong>Vater:</strong> König Andreas von Ungarn
    </p>
    <p>
      <strong>Mutter:</strong> Getrud von Andechs
    </p>
    <p>
      <strong>Verheiratet:</strong> 1221 mit Ludwig von Thüringen
    </p>
    <p>
      <strong>Kinder:</strong> Hermann, Sophie und Gertrud
    </p>
    <p>
      <strong>Verstorben:</strong> 17. November 1231 in Marburg
    </p>
    <p>
      <strong>Heiligsprechung:</strong> 1235
    </p>
    <br>
    <div>
      <h2>Hl. Elisabeth:</h2>
      <p>
        Wer sich mit der heiligen Elisabeth beschäftigt, begegnet einer Frau, über die sich durchaus kontrovers diskutieren lässt.
      </p>
      <p>
        Wie es in königlichen Kreisen im 13. Jahrhundert üblich war, wurde Elisabeth schon im frühesten Kindesalter dem Landgrafen von Thüringen als Ehefrau versprochen. Sie war erst 14 Jahren alt, als sie Ludwig von Thüringen heiratete.
      </p>
      <p>
        Obwohl die Ehe eine unter den Ländern Ungarn und Thüringen arrangierte Hochzeit war, liebten sich Elisabeth und Ludwig sehr. Eine Tatsache, die es Elisabeth überhaupt erst ermöglichte, ihre caritative Einstellung in Taten umzusetzen.
      </p>
      <p>
        Sehr bald war Elisabeth der Prunk, der Reichtum und die Verschwendung von Lebensmitteln am Hof ein Dorn im Auge. Ihr Anliegen war es, Kranken, Bedürftigen, Kindern und Armen zu helfen. Dies erwies sich jedoch als problematisch, da dieses Wirken in keiner Weise den zu dieser Zeit herrschenden gesellschaftlichen Normen und Etikette entsprach. Ganz im Gegenteil, der Hochadel wollte weder teilen noch anderen helfen.
      </p>
      <p>
        Berufene Fürstinnen und sonstige Untertanen hatten kein Interesse, Kleider für Arme zu nähen oder pflegerisch tätig zu werden. Elisabeth half dennoch den Bedürftigen und verlangte es auch von ihrem Hofstaat. So ist es nicht verwunderlich, dass Elisabeth bekämpft und verachtet wurde.
      </p>
      <p>
        Dass ihr Mann durch ihre caritative Arbeit ebenfalls häufig angefeindet wurde, nahm sie billigend in Kauf.  Elisabeth hatte geschworen, dass sie, falls Ludwig sterben sollte, nie wieder heiraten würde. Leider waren ihnen nur sechs Ehejahre vergönnt. Ludwig starb auf einem Kreuzweg an einer Infektion.
      </p>
      <p>
        Ihre caritative Mission wurde nach dem Tod Ludwigs wesentlich erschwert, da sie vom Hof vertrieben wurde. Niemand hatte Interesse daran, ihren eingeschlagenen Weg zu unterstützen.
      </p>
      <p>
        Elisabeth war sehr gläubig und wünschte sich schon sehr früh einen geistlichen Begleiter. Ludwig hatte sich gleich nach ihrer Eheschließung um einen geeigneten Mann bemüht und in Konrad von Marburg den richtigen gefunden.
      </p>
      <p>
        Konrad war streng, forderte ihm gegenüber und dem Evangelium uneingeschränkten Gehorsam. Über die extreme Form körperlicher Askese, die Elisabeth häufig fast in den Tod getrieben hat, war er allerdings immer besorgt.
      </p>
      <p>
        Elisabeth ging ihren eingeschlagenen Weg stets konsequent und ohne Rücksicht auf Verluste. Sie war eine fröhliche, spontane, freche, gefühlsbetonte, eigenwillige und selbstbewusste Frau. Sie war egozentrisch, altruistisch und provokativ.
      </p>
      <p>
        Sie nahm in Kauf, dass ihr unmittelbares Umfeld, auch ihr Mann und ihre Kinder, durch ihr Wirken litten. Aber sie stand zu dem, was sie tat, half bedingungslos und wurde dadurch eine Vorreiterin einer organisierten caritativen Bewegung im Sinne der Barmherzigkeit.
      </p>
      <p>
        Bischof Joachim Wanke überträgt in dem Buch „Mehr als Brot und Rosen“ diese Werke der Barmherzigkeit und somit das Wirken der Hl. Elisabeth in die heutige Zeit: „Einem Menschen sagen: Du gehörst dazu! Ich höre Dir zu, Ich rede gut über Dich, Ich gehe ein Stück mit Dir, Ich teile mit Dir, Ich besuche Dich, Ich bete für Dich."
      </p>
      <p>
        Ein schöner Vergleich, mit dem vielleicht jeder etwas anfangen kann. Vielleicht sind es die Schwächen, die Elisabeth stark machten. Vielleicht wird sie genau deswegen so verehrt, weil sie war wie sie war. Vielleicht verehren deswegen sowohl Katholiken als auch Protestanten die Hl. Elisabeth, weil sie zuallererst einfach nur ein Mensch war und nur auf den zweiten Blick eine Heilige. Vielleicht …
      </p>
    </div>
    <h2>Legende</h2>
    <p>
      Nahezu täglich stieg die Hl. Elisabeth von der Wartburg herab, um Armen und Kranken Brot zu bringen.
    </p>
    <p>
      Das Brot versteckte sie in einem Deckelkorb unter ihrer Schürze. Ihr Mann Ludwig, wieder einmal von seiner Umgebung wegen der Verschwendung von Lebensmittel an Bedürftige aufgehetzt, hielt sie auf und fragte: „Was verbirgst Du unter Deiner Schürze?“ In ihrer Angst erwiderte die Hl. Elisabeth: „Nichts als Rosen, mein geliebter Mann.“
    </p>
    <p>
      Zornig ob dieser Antwort riss Ludwig ihr die Schürze weg und hob den Deckel des Korbes. Demütiges Staunen erfasste ihn, als er im Korb lauter Rosen liegen sah. Von dem Tag an trat der Hl. Elisabeth niemand mehr in den Weg, wenn sie zu den Armen und Kranken ging.
    </p>
    <strong>Quellen:</strong>
    <ul>
      <li>
        Barbara Stühler: Elisabeth von Thüringen, Spiritualität-Geschichte-Wirkung, topos 2018
      </li>
      <li>
        Algermissen, Hein, Kähler, Wanke: Mehr als Brot und Rosen, Herder 2007
      </li>
    </ul>
  </div>
</div>
</template>

<script>
export default {
  name: "Verein"
}
</script>

<style scoped>
li a {
  transition: all 100ms ease-in-out;
}
</style>