import VueRouter from "vue-router";
import Index from '@/views/Index';
import Vue from 'vue';
import Verein from "@/views/Verein";
import MitgliedWerden from "@/views/MitgliedWerden";
import HeiligeElisabeth from "@/views/HeiligeElisabeth";
import Datenschutz from "@/views/footer/Datenschutz";
import Kontakt from "@/views/footer/Kontakt";
import Impressum from "@/views/footer/Impressum";
import StellenAngebote from "@/views/StellenAngebote";
import VueScrollTo from "vue-scrollto"; // Use VueScrollTo for animation.

Vue.use(VueRouter)

const routes = [
    {
        path:'/', name: 'index', component: Index
    },
    {
        path:'/verein', name: 'verein', component: Verein
    },
    {
        path:'/heilige_elisabeth', name: 'heilige_elisabeth', component: HeiligeElisabeth
    },
    {
        path:'/mitglied_werden', name: 'mitglied_werden', component: MitgliedWerden
    },
    {
        path:'/stellenangebote', name: 'stellenangebote', component: StellenAngebote
    },
    {
        path:'/kontakt', name: 'kontakz', component: Kontakt
    },
    {
        path:'/impressum', name: 'impressum', component: Impressum
    },
    {
        path:'/datenschutz', name: 'datenschutz', component: Datenschutz
    }

]

const router = new VueRouter({
    routes, // short for `routes: routes
    mode: "history",
    linkActiveClass: "active",
    linkExactActiveClass: "is-active",
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            // Use for direct jump.
            //window.location.href = to.hash;
            // Use VueScrollTo for animation.
            VueScrollTo.scrollTo(to.hash);
            //return {el: to.hash};
        } else if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 }
        }
    }
});

export default router;