<template>
  <div>
    <h1 class="title">Liebe Besucherinnen und Besucher!</h1>
    Herzlich Willkommen auf der Website des St. Elisabethenvereines Neunkirchen am Brand e.V.
    <br>
    <br>
    Hier finden Sie alles Wissenswertes rund um unseren Verein, die Trägeraufgaben des Kindergartens St. Elisabeth und der Kinderkrippe St. Elisabeth, die Vereinsgeschichte und unsere Namensgeberin, die Hl. Elisabeth.
    <br>
    <br>
    Getreu unserem Motto „Du gehörst dazu“ freuen wir uns, wenn Sie mit uns in Kontakt treten oder Mitglied werden. Weitere Informationen hierzu finden Sie in den dazugehörigen Menüpunkten.
    <br>
    <br>
    Wir wünschen Ihnen viel Freude beim Lesen und Informieren!
  </div>
</template>

<script>
export default {
  name: "Index"
}
</script>

<style scoped>

</style>