<template>
  <aside class="menu sticky">
    <ul class="menu-list">
      <li class="mb-4">
        <router-link
            id="start-item"
            :to="{name: 'index'}"
            class="has-text-right pr-6">
          Startseite
        </router-link>
      </li>
      <li class="mb-4">
        <router-link
            :to="'/verein'"
            class="has-text-right pr-6 toggle-link"
            @click.native="toggleHide">
          Verein
        </router-link>
        <ul id="verein" class="menu-list mt-0 is-hidden">
          <li>
            <router-link
                :to="{name: 'verein', hash: '#aufgaben'}"
                class="has-text-right pr-6 toggle-link">
              Aufgaben
            </router-link>
          </li>
          <li>
            <router-link
                :to="{name: 'verein', hash: '#leitbild'}"
                class="has-text-right pr-6">
              Leitbild
            </router-link>
          </li>
          <li>
            <router-link
                :to="{name: 'verein', hash: '#vision'}"
                class="has-text-right pr-6">
              Vision
            </router-link>
          </li>
          <li>
            <router-link
                :to="{name: 'verein', hash: '#logo'}"
                class="has-text-right pr-6"
            >
              Logo
            </router-link>
          </li>
          <li>
            <router-link
                :to="{name: 'verein', hash: '#geschichte'}"
                class="has-text-right pr-6"
            >
              Geschichte
            </router-link>
          </li>
          <li>
            <router-link
                :to="{name: 'verein', hash: '#vorstandschaft'}"
                class="has-text-right pr-6"
            >
              Vorstandschaft
            </router-link>
          </li>
          <li>
            <router-link
                :to="{name: 'verein', hash: '#mitarbeiter'}"
                class="has-text-right pr-6"
            >
              Mitarbeiter
            </router-link>
          </li>
          <li>
            <router-link
                :to="{name: 'verein', hash: '#satzung'}"
                class="has-text-right pr-6">
              Satzung
            </router-link>
          </li>
        </ul>
      </li>
      <li class="mb-4">
      </li>
      <li class="mb-4">
        <router-link
            :to="{name: 'heilige_elisabeth'}"
            class="has-text-right pr-6">
          Heilige Elisabeth
        </router-link>
      </li>
      <li class="mb-4">
        <router-link
            :to="{name: 'mitglied_werden'}"
            class="has-text-right pr-6">
          Mitglied werden
        </router-link>
      </li>
      <li class="mb-4">
        <router-link
            :to="{name: 'stellenangebote'}"
            class="has-text-right pr-6">
          Stellenangebote
        </router-link>
      </li>
    </ul>
  </aside>
</template>

<script>
export default {
  name: "SideMenu",
  methods: {
    toggleHide(event) {
      let target = event.target.parentNode.getElementsByTagName('ul')[0];
      if (target.classList.contains('is-hidden')) {
        target.classList.remove('is-hidden');
      } else {
        target.classList.add('is-hidden');
      }
    }
  }
}
</script>

<style scoped>
</style>