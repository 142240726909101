<template>
  <div id="verein-content">
    <section class="section" id="aufgaben">
      <h1 class="title">Aufgaben des St. Elisabethenvereines</h1>
      <div class="content">
        <p>
          Der Elisabethenverein e.V. Neunkirchen am Brand ist Sachaufwands- und Betriebsträger der Kinderkrippe St. Elisabeth, des Kindergartens St. Elisabeth und Arbeitgeber des dort beschäftigten Personals, sowie der Verwaltungsangestellten des Vereines.
          Gesetzliche Grundlage für die Kitas ist das Bayerische Gesetz zur Bildung, Erziehung und Betreuung von Kindern in Kindergärten, anderen Kindertageseinrichtungen und in der Tagespflege. (Bayerisches Kinderbildungs- und -betreuungsgesetz – BayKiBiG)
          Auszüge aus den Aufgaben:
        </p>
        <br>

        <h2 class="subtitle">
          Sachaufwandsträger
        </h2>
        <p>
          Erstellung, Ausstattung, Unterhaltung und Instandsetzung der Betriebsstätten (Gebäude / Grundstücke) Kinderkrippe und Kindergarten.
        </p>
        <br>
        <h2 class="subtitle">
          Betriebsträgeraufgaben
        </h2>
        <p>
          Gültige Betriebserlaubnis gewährleisten, Personalverwaltung, IT- Software, ggf. Beauftragung externer Dienstleister, Versicherungswesen, Förderungen und Zuschüsse, Weiterleitung und Pflege förderrelevanter Daten, Haushaltsplanung und -überwachung, Personalmeldungen, Führungszeugnisse, Betreuungsverträge, Arbeitssicherheit und -gesundheit, Gebäudesicherheit mit Prüfungen (Brandschutz, Trinkwasser, Elektrogeräte, Feuerlöscher, Geländesicherheit, Spielgeräte, Arbeitsplätze), Hygienepläne, Gefährdungsbeurteilungen, Elternbeiträge, Zusammenarbeit mit übergeordneten Stellen (Caritas Erzbistum, Landratsamt, Markt Neunkirchen am Brand), Öffentlichkeitsarbeit
        </p>
        <br>
        <h2 class="subtitle">
          Arbeitgeberaufgaben
        </h2>
        <p>
          Personalakquise, Personalentwicklung, Personalbindung, Personalförderung, Personalfürsorge, Stellenbesetzung, Auswahlverfahren, Dienst- und Fachaufsicht, Belehrungen, Organigramm, Stellenbeschreibungen, Dienstgespräche, Mutterschutz, Personalrecht, Konfliktintervention, Abmahnungen, Personalschlüssel, Freistellungen für Leitungsaufgaben, Personalausstattung Hauswirtschaft, Küche, Hausmeister, Verwaltung, Teamentwicklung, Fort- und Weiterbildungsmöglichkeiten, Dienstanweisungen, Urlaub, Krankmeldungen, Jugendschutz, Mitarbeitergespräche, Konzeption, Qualitätsmanagement, Kooperationen mit Kirche und Kirchengemeinde, Elternbeirat, Umsetzung Leitbild, Religionspädagogik, Familienstützpunkt
        </p>
        <br>
        <h2 class="subtitle">
          Vereinsaufgaben
        </h2>
        <p>
          Erfüllung des Vereinszweckes und den damit verbundenen Aufgaben, Abwicklung des Vereins während des Jahres im Sinne des Vereinsrechtes, Mitgliederversammlungen, Mitgliederverwaltung Vorstandschaftssitzungen, Finanzverwaltung, Fundraising, Öffentlichkeitsarbeit, Betreuung weiterer Liegenschaften, Weiterentwicklung des Vereines
        </p>
        <br>
        <h2 class="subtitle">
          Ökumenischer Familienstützpunkt
        </h2>
        <p>
          Dieser ist eine Kooperation mit der Ev.-Luth. Kirchengemeinde, Diakonie und Caritas. Der St. Elisabeth bringt sich in Form von monetärer Beteiligung ein.
        </p>
      </div>
    </section>

    <section id="leitbild" class="section">
      <h1 class="title">Leitbild</h1>
      <p>
        Der St. Elisabethenverein handelt nach dem Vorbild der Hl. Elisabeth, sowie den Grundsätzen der christlichen Nächstenliebe und des christlichen Weltbildes.
        Der St. Elisabethenverein nimmt sich jedem an, vom Kind bis zum Hochbetagten und schätzt jeden als wertvolles Geschöpf Gottes. Der St. Elisabethenverein betreut nicht nur wie es die Satzung und das Gesetz vorgibt, sondern nimmt alle gleichermaßen liebevoll in seine Gemeinschaft auf.
        Das soziale Engagement kommt allen Menschen zugute, unabhängig von Geschlecht, Konfession und Nationalität.
        Der St. Elisabethenverein ist der Katholischen Pfarrgemeinde St. Michael / St. Augustinus eng verbunden.
      </p>
    </section>

    <section id="vision" class="section">
      <h1 class="title">Vision</h1>
      <p>
        Der St. Elisabethenverein engagiert sich derzeit vollumfänglich im Bereich der Kindertagesstätten.
        Das Thema „Alte Menschen“ ist dem Elisabethenverein nach wie vor eine Herzensangelegenheit und wurde ganz bewusst in der Vereinssatzung belassen. Es besteht der Wunsch, für diesen Tätigkeitsbereich Engagierte zu finden, die sich dort einbringen.
      </p>
    </section>

    <section id="logo" class="section">
      <h1 class="title">Logo</h1>
      <p>
        Die Rosen in unserem Logo sind auf die Legende (siehe Menüpunkt Hl. Elisabeth) um die Hl. Elisabeth zurückzuführen. Die weißen Linien in den Rosen symbolisieren Bewegung, im eigentlichen und im übertragenen Sinne. Die Farbe Rot steht zum einen für die Liebe, die auch Elisabeth stärkte, und zum anderen für Power, die vieles entstehen lässt. Das Orange verkörpert die Güte und die Wärme und soll zum Ausdruck bringen: Du gehörst dazu. Die geschwungene Linie über den Rosen stellt das Dach des St. Elisabethenvereines dar, unter dem auch der Kindergarten und die Krippe steht, aber auch Gottes Segen über allem.
      </p>
    </section>

    <section id="geschichte" class="section">
      <h1 class="title">Geschichtlicher Überblick</h1>
      <p>
        Der St. Elisabethenverein kann stolz auf eine über 100 - jährige Erfolgsgeschichte zurückblicken. Entscheidungen, die damals getroffen wurden, waren revolutionär und ihrer Zeit stets weit voraus.<br>
        <br>
        Am 03. März 1912 wurde der St. Elisabethenverein gegründet. Der Vereinszweck war die Installation einer ambulanten Krankenpflegestation. Diese war in ihrer Art die erste im Landkreis Forchheim. Mit der Pflege wurden die Niederbronner Schwestern beauftragt. Da die Nachfrage groß war und im Laufe der Zeit mehr Schwestern ihre Tätigkeit aufnahmen, erwarb der St. Elisabethenverein im Jahr 1920 das Anwesen „Schrödelhaus“ im Mühlweg, um den Schwestern einen Ort zum Leben anbieten zu können.<br>
        <br>
        Bereits im Jahr 1922 dachte der St. Elisabethenverein über die Einrichtung einer Kinderschule nach. Dem zufolge sammelten die Aktiven des St. Elisabethenvereines in groß angelegten Fundraisingaktionen Geld, um eine neue Immobile kaufen zu können.1930 wurde das erworbene und umgebaute St. Elisabethenheim im Mühlweg 1 schließlich eingeweiht. Die erste Neunkirchener Kinderschule (Kindergarten) war mit Garten und Liegehalle ausgestattet und öffnete 1931 für 80 angemeldete Kinder ihre Pforten.<br>
        <br>
        Noch im gleichen Jahre startete die stationäre Altenpflege. Bemerkenswert ist, dass die Aktiven des St. Elisabethenvereines sowohl den Umständen und Wirren des Ersten als auch Zweiten Weltkrieges trotzten. Es gelang ihnen, nach Währungsreformen, der Umfunktionierungen in Lazarett, Schule, Erholungsheim und Flüchtlingsdomizil sowie amerikanischer Besatzung das Heim wieder seinem ursprünglichen Zweck zuzuführen.<br>
        <br>
        Die Nachkriegsjahre bis weit in die 70-iger prägten die Sorge um das Anwesen im Mühlweg. Renovierungen, Sanierungen, zeitliche Anpassungen und Forderungen warfen ständig neue Finanzierungsprobleme auf. Das Projekt St. Elisabethenheim war autark nicht mehr zu schultern, und die Kinderschule platzte bereits aus allen Nähten. So stellte der St. Elisabethenverein für den nötigen Neubau eines Kindergartens das Grundstück auf Erbpachtbasis zur Verfügung. Bauherr wurde die Erzbischöfliche Seminarstiftung mit Unterstützung des Marktes, des Landkreises und des Kultusministeriums. Im Dezember 1963 wurde das Gebäude eingeweiht und seiner Bestimmung übergeben. Da die Finanzlage um das St. Elisabethenheim immer erdrückender wurde, entschied der St. Elisabethenverein schließlich, das Anwesen 1974 an die Erzbischöfliche Seminarstiftung zu verkaufen. Die Betriebsträgerschaft wurde beibehalten. Die Sanierung und der Umbau des St. Elisabethenheimes begann 1975.<br>
        <br>
        Da die Kapazität des Kindergartens bereits überschritten war, eine Erweiterung des vorhandenen Gebäudes jedoch beim Landratsamt keine Zustimmung fand, wurde ein 5-gruppiger Neubau beschlossen. Der St. Elisabethenverein brachte den Erlös des St. Elisabethenheimes in die Finanzierung ein.<br>
        <br>
        1976 wurde die erste Sozialstation des Landkreises Forchheim noch während des Umbaus im Anwesen Mühlweg eröffnet. Die Caritas übernahm die ambulante Pflege, während der stationäre Betrieb mit 21 Pflegebedürftigen wie gewohnt weiterlief. Die stationäre Pflege sowie Leitung der Sozialstation und des Kindergartens wurde also weiterhin von den Niederbronner Schwestern ausgeübt.<br>
        <br>
        1978 wurde schließlich das Jahr der Einweihungen. So konnten der neu gebaute Kindergarten und das umgebaute St. Elisabethenheim in Betrieb gehen. Nachdem der administrative Aufwand immer höher wurde, fand im Jahr 1978 erstmals eine Verwaltungskraft ihre Anstellung. Ihr Büro richtete der St. Elisabethenverein selbstverständlich im Mühlweg ein. Gleichzeitig eröffnete die erste Pfarrbücherei und der Kolpingjugend konnten auch noch Räume zur Verfügung gestellt werden.<br>
        <br>
        Die Zahl der Angestellten des St. Elisabethenvereines belief sich im Jahr 1978 bereits auf 29 Mitarbeiterinnen und Mitarbeiter.<br>
        <br>
        Drei Jahre nach den Einweihungsfeierlichkeiten folgte 1981 das große Jubiläumsjahr. Der St. Elisabethenverein blickte stolz auf 70 Jahre Tätigkeit der Niederbronner Schwestern sowie auf 50 Jahre Kindergarten und stationäre Altenpflege in Neunkirchen am Brand zurück. Der Kindergarten erfreute sich so hoher Nachfrage, dass insgesamt 250 Kinder in 9 Gruppen betreut wurden. Es gab eine Ganztagsgruppe und im Wechsel wurden vier Gruppen vormittags und vier Gruppen nachmittags betreut.<br>
        <br>
        Im Jahr 1984 überschritt der Verwaltungshaushalt erstmals die Millionengrenze. Die Hauptaufgaben   waren die Instandhaltung der Liegenschaften und die Personalverwaltung mit allen Arbeitgeberpflichten.<br>
        <br>
        Der St. Elisabethenverein wäre nicht der St. Elisabethenverein, würde er sich auf seinen Leistungen ausruhen. So wurden 1984 die Weichen für ein Altenheim in Form einer Bedarfsplanung gestellt. Zunächst bezogen sich die Gespräche auf eine Erweiterung des Gebäudes im Mühlweg. Doch das eingeschränkte Platzangebot zerschlug diese Überlegungen. Es begann die Suche nach einem passenden Grundstück, die schließlich erfolgreich war. Durch Tauschgeschäfte mit dem Markt Neunkirchen am Brand ging das Grundstück, auf dem das Alten- und Pflegeheim St. Elisabeth bis heute steht, 1990 in das Eigentum der Pfarrei St. Michael / St. Augustinus über. Die Vorfreude auf die bald beginnenden Planungen im Jahr 1992 wurde jedoch durch die Hiobsbotschaft getrübt, dass die Niederbronner Schwestern aus Neunkirchen am Brand abgezogen würden. Durch Gespräche mit der Provinzoberin konnte immerhin ein fünfjähriger Aufschub erwirkt werden. Dies ermöglichte es den Schwestern, den Bau des Altenheim St. Elisabeth mitzuerleben und ihre Erfahrungen einzubringen. Am 19. November 1995, dem Namenstag der Hl. Elisabeth, wurde das neu errichtete Gebäude eingeweiht. In der darauffolgenden Woche, am 26. November 1995 wurden die Niederbronner Schwestern nach    83 - jähriger Tätigkeit in Neunkirchen am Brand dankbar verabschiedet. Im gleichen Zeitraum konstituierte sich eine ausgesprochen aktive Kontaktgruppe, die sich ehrenamtliche Dienste, im nicht pflegerischen Bereich im Altenheim zur Aufgabe machten. Da die Anforderungen einer Betriebsträgerschaft eines solchen Heimes im Laufe der Zeit immer komplexer wurden, sah der St. Elisabethenverein hinsichtlich dessen Handlungsbedarf. Die Aufgaben waren ehrenamtlich nicht mehr zu leisten. So ging die Trägerschaft 2001 an Caritas über. Der Verkauf des Alten- und Pflegeheimes St. Elisabeth an Caritas erfolgte dann im Jahr 2011.<br>
        <br>
        Durch gezielte Grundstückskäufe zur Errichtung einer neuen Sozialstation mit Tagespflege sowie zum Bau von altersgerechten Mietwohnungen in der Von - Pechmann - Straße trug der Verein wesentlich zur Entstehung eines sozialen Zentrums in Neunkirchen am Brand bei. Die Grundstücke wurden den jeweiligen Trägern in Erbpacht überlassen.<br>
        <br>
        Viel Engagement und finanzieller Aufwand wurde in die Instandhaltung und Renovierung des in die Jahre gekommenen Kindergarten gesteckt. Sowohl das Gebäude als auch der Garten bedurften der dringenden Erneuerung. Die erste aufwendige Teilsanierung zog sich über Jahre hin, bis sie 2012 ihren Abschluss fand. Leider sollte es dabei nicht bleiben. Weitere Sanierungsetappen folgten.<br>
        <br>
        Inzwischen zeigte sich im Markt Neunkirchen der Bedarf einer Kinderkrippe. So ging man im St. Elisabethenverein wieder mit der Zeit und erbaute eine Kinderkrippe, die 2009 ihren Betrieb aufnahm. Da der Bedarf an Krippenplätzen in Neunkirchen am Brand kontinuierlich wuchs, begannen bereits drei Jahre später die Planungen für eine weitere Kinderkrippe. Schlag auf Schlag folgte die Umsetzung. Der Baubeginn war im Frühjahr 2013, die Einweihung und Inbetriebnahme schon im darauffolgenden Frühjahr.<br>
        <br>
        Ein Starkregen im Sommer 2015, der einen großen Wasserschaden im Kindergarten verursachte, hatte eine Generalsanierung des gesamten Dachkomplexes zur Folge. Im Sommer 2016 wurde der morsche Holzzaun zur Einfriedung des Kindergartengeländes durch einen Metallzaun ersetzt. Schließlich galt es 2016 auf den gesellschaftlichen Wandel zu reagieren, dass immer mehr Frauen berufstätig sind. Im Kindergarten zeichnete sich dieser durch eine höhere Belegung über die Mittagszeit hinaus ab. Die Küche musste auf die neuen Bedürfnisse ausgerichtet werden und ein Speiseraum musste angebaut werden. Diese Maßnahmen ermöglichen seither die Ausgabe eines warmen Mittagessens. Trotz der fortlaufenden Sanierungen und der damit verbundenen Investitionen ließ es sich der St. Elisabethenverein nicht nehmen, im Juli 2018 das 40 - jährige Bestehen des Kindergartens am jetzigen Standort zu feiern!<br>
        <br>
        In weiser Voraussicht erwarb 2018 der St. Elisabethenverein ein weiteres Grundstück neben dem „Haus Franziskus“. Die demographische Entwicklung und die Ausschreibung neuer Baugebiete in Neunkirchen am Brand ließen Rückschlüsse zu, dass zu gegebener Zeit ein Bau einer dritten Krippe nötig sein würde. 2019 hat sich diese Prognose bewahrheitet und die Planungen für eine weitere Einrichtung wurden aufgenommen. Baubeginn war im September 2021, bereits zum 01 Januar 2023 konnte der Betrieb im neuen Haus "Hanna" aufgenommen werden.<br>
        <br>
        Im Frühjahr 2020 erfolgte die Erneuerung sämtlicher sanitären Einrichtungen im Kindergarten. Damit ist nun die auf Etappen durchgeführte Generalsanierung, die den St. Elisabethenverein nahezu ein Jahrzehnt beschäftigt hat, abgeschlossen und die Einrichtung auf neuestem Stand.<br>
        <br>
        (Quelle: Festschrift 100 Jahre St. Elisabethenverein Neunkirchen am Brand)
      </p>
    </section>

    <section id="vorstandschaft" class="section">
      <h1 class="title">Vorstandschaft</h1>
      <div class="content">
        <ul>
          <li>1.Vorsitzender: Pfarrer Joachim Cibura</li>
          <li>2. Vorsitzende: Pia Mager</li>
          <li>Finanzverwalter: Josef Geist</li>
          <li>Beisitzerin: Nina Dumser</li>
          <li>Beisitzerin: Simone Seubert</li>
          <li>Beisitzerin: Christine Sittert</li>
          <li>Beisitzer: Hermann Hahn</li>
        </ul>
      </div>
    </section>

    <section id="mitarbeiter" class="section">
      <h1 class="title">Mitarbeiter</h1>
      <div class="content">
        <h2>Verwaltung</h2> Christine Kröhn und Heike Gutzeit
        <br>
        <br>
        <p>
          Die aktuellen Mitarbeiter in den unterschiedlichen Kindertageseinrichtungen finden Sie auf der Homepage von Kinderkrippe und Kindergarten (siehe Links weiter oben).
        </p>
      </div>
    </section>

    <section id="satzung" class="section">
      <h1 class="title">Satzung</h1>
<!--      <embed src="files/elisabethenverein_satzung_2019.pdf" type="application/pdf" width="100%" height="1200"/>-->
      <iframe src="files/elisabethenverein_satzung_2019.pdf" width="100%" height="1200"></iframe>
    </section>
  </div>
</template>

<script>
export default {
  name: "Verein",
  data() {
    return {
      observer: null,
      links: []
    }
  },
  methods: {
    addIsActive: function (id){
      let selector = `ul li a[href="/verein#${id}"]`;
      let link = document.querySelector(selector);
      link.classList.add('is-active');
    },
    removeIsActive: function (id) {
      let link = document.querySelector(`ul li a[href="/verein#${id}"]`);
      link.classList.remove('is-active');
    }
  },
  mounted() {
    document.getElementById('verein').classList.remove('is-hidden');
    this.observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        const id = entry.target.getAttribute('id');
        if (entry.intersectionRatio > 0) {
          this.addIsActive(id)
        } else {
          this.removeIsActive(id);
        }
      });
    });

    this.links = document.querySelectorAll('section[id]');

    this.links.forEach((section) => {
      this.observer.observe(section);
    });
  },
  beforeDestroy() {
    this.links.forEach(entry =>{
      const id = entry.id;
        this.removeIsActive(id);
    });

    document.getElementById('verein').classList.add('is-hidden');


    this.observer.disconnect();
  }
}
</script>

<style scoped>
.section:not(:first-child) {
  padding: 3rem 0 0 0;
}

.section:first-child {
  padding: unset;
}
</style>